import { HOME_PAGE_QUERY } from '@data/home';
import { articles, offers, page as pageQuery, partners, services } from '@data/queries';
import dynamic from 'next/dynamic';
import { ReactElement } from 'react';
import { ArticleProps } from 'src/@types/article';
import { OfferProps } from 'src/@types/offer';
import { PageProps } from 'src/@types/page';
import { PartnerProps } from 'src/@types/partner';
import { SanityHomePage } from 'src/@types/sanity';
import { ServiceProps } from 'src/@types/service';
import Module from 'src/components/modules/Module';
import { getClient } from 'src/lib/sanity.client';
import Routes from 'src/routes';
import { LandingHero } from 'src/sections/landing';
import { Page } from '../src/components';
import Layout from '../src/layouts';

const DynamicNewsletter = dynamic(() => import('src/sections/newsletter/Newsletter'));
const DynamicPosts = dynamic(() => import('src/sections/blog/BlogLatestPosts'));
const DynamicPartners = dynamic(() => import('src/sections/partners/Partners'));

// ----------------------------------------------------------------------

type Props = {
  data: {
    offers: OfferProps[];
    services: ServiceProps[];
    articles: ArticleProps[];
    partners: PartnerProps[];
  };
  page: PageProps;
  home: SanityHomePage;
};

export default function LandingPage({ page, data, home }: Props) {
  return (
    <Page seo={page.seo} canonical="https://www.b-e-a.no/">
      {home.hero && <LandingHero data={home.hero} />}

      {home.modules.map((module) => (
        <Module key={module._key} module={module} />
      ))}

      <DynamicPartners partners={data.partners} />

      <DynamicPosts articles={data.articles} />

      <DynamicNewsletter />
    </Page>
  );
}

// ----------------------------------------------------------------------

LandingPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

// ----------------------------------------------------------------------

export async function getStaticProps() {
  const query = `
    {
      "articles": ${articles(5)},
      "services": ${services},
      "offers": ${offers(4)},
      "partners": ${partners}
    }
  `;

  const data = await getClient().fetch(query);
  const page = await getClient().fetch(pageQuery, { slug: Routes.home });
  const home = await getClient().fetch(HOME_PAGE_QUERY);

  return {
    props: { page, data, home }, // will be passed to the page component as props
  };
}
