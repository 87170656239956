import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { SanityModuleOffers } from 'src/@types/sanity';
import { Swiper, SwiperSlide } from 'swiper/react';
import OfferModule from './Offer';

import NextLink from 'next/link';
import { useResponsive } from 'src/hooks';
import Routes from 'src/routes';
import 'swiper/css';
import { A11y, FreeMode } from 'swiper/modules';

type Props = {
  module: SanityModuleOffers;
};

export default function OffersModule({ module }: Props) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <Paper sx={{ py: isDesktop ? 8 : 5, width: 1, overflowX: 'hidden' }}>
      <Container sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">{module.title}</Typography>
          <NextLink href={Routes.offers} style={{ color: 'inherit' }}>
            <Button variant="outlined" color="inherit">
              Se alle
            </Button>
          </NextLink>
        </Stack>
      </Container>
      <Container>
        {isDesktop && (
          <Grid container spacing={2}>
            {module.modules.slice(0, 4).map((offer) => (
              <Grid key={offer._key} item xs={12} md={6}>
                <OfferModule module={offer} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      {!isDesktop && (
        <Swiper
          modules={[A11y, FreeMode]}
          speed={200}
          spaceBetween={10}
          slidesPerView={1.5}
          autoHeight
          style={{ paddingLeft: 16, paddingRight: 16, overflow: 'visible', zIndex: 0 }}
        >
          {module.modules.map((offer) => (
            <SwiperSlide key={offer._key}>
              <OfferModule module={offer} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Paper>
  );
}
