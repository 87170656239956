import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import PostBody from '@utils/PostBody';
import {
  SanityAssetImage,
  SanityModuleCallToAction,
  SanityProductWithVariant,
} from 'src/@types/sanity';
import { dataset, projectId } from 'src/lib/sanity.client';
import SanityImage from '../media/SanityImage';

type Props = {
  module: SanityModuleCallToAction;
};

export default function CallToActionModule({ module }: Props) {
  return (
    <Paper sx={{ bgcolor: 'grey.200', py: 10, my: 8 }}>
      <Container>
        <Stack
          direction={
            module.layout === 'left'
              ? { xs: 'column', md: 'row' }
              : { xs: 'column-reverse', md: 'row-reverse' }
          }
          spacing={5}
        >
          {/* <Box
          sx={{ display: 'flex' }}
          className={clsx(
            'flex gap-5 md:gap-[5vw]', //
            module.layout === 'left' && 'flex-col md:flex-row',
            module.layout === 'right' && 'flex-col-reverse md:flex-row-reverse'
          )}
        > */}
          <Box
            sx={{
              position: 'relative',
              aspectRatio: '864/485',
              flexGrow: 1,
              borderRadius: 2,
              overflow: 'hidden',
            }}
          >
            {module.content && <ModuleContent content={module.content} />}
          </Box>

          <Box
            sx={{
              maxWidth: '40rem',
              mr: 'auto',
              display: 'flex',
              width: 1,
              flexShrink: 0,
              flexDirection: 'column',
              flexItems: 'start',
            }}
          >
            {/* <div
            className={clsx(
              'mr-auto flex w-full shrink-0 flex-col items-start', //
              'md:max-w-[20rem]'
            )}
          > */}
            {/* Title */}
            <Typography variant="h2" sx={{ mb: 2 }}>
              {module.title}
            </Typography>
            {/* <div
              className={clsx(
                'text-xl font-bold', //
                'md:text-2xl'
              )}
            >
              {module.title}
            </div> */}

            {/* Body */}
            {module.body && (
              <Box sx={{ '& p': { color: 'text.secondary' } }}>
                <PostBody content={module.body} />
              </Box>
            )}

            {/* Link */}
            {module.link && <Box mt={2}>{module.link.title}</Box>}
            {/* </div> */}
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
}

function ModuleContent({ content }: { content: SanityAssetImage | SanityProductWithVariant }) {
  switch (content?._type) {
    case 'image': {
      return (
        <SanityImage
          alt={content?.altText}
          crop={content?.crop}
          dataset={dataset}
          hotspot={content?.hotspot}
          layout="fill"
          objectFit="cover"
          projectId={projectId}
          sizes="100vw"
          src={content?.asset?._ref}
        />
      );
    }
    default:
      return null;
  }
}
