import { Box, Button, Card, Typography } from '@mui/material';
import NextLink from 'next/link';
import { SanityAssetImage, SanityModuleService } from 'src/@types/sanity';
import { dataset, projectId } from 'src/lib/sanity.client';
import Routes from 'src/routes';
import SanityImage from '../media/SanityImage';

type Props = {
  module: SanityModuleService;
};

export default function ServiceModule({ module }: Props) {
  const { header, slug, title } = module.service;

  return (
    <NextLink
      href={Routes.service(slug.current)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Card
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          height: 1,
        }}
      >
        <Box px={3} pt={2} bgcolor="common.white">
          <Typography
            variant="h5"
            sx={{ height: (theme) => `calc(${theme.typography.h5.lineHeight} * 1em * 2)` }}
          >
            {title}
          </Typography>
          <Button sx={{ mb: -4, zIndex: 2 }} size="small" variant="contained" color="inherit">
            Les mer
          </Button>
        </Box>
        <Box
          sx={{
            position: 'relative',
            aspectRatio: '1/1',
            width: 1,
            left: 0,
          }}
        >
          <ModuleImage isFilled={true} image={header} />
        </Box>
      </Card>
    </NextLink>
  );
}

function ModuleImage({ image, isFilled }: { image: SanityAssetImage; isFilled: boolean }) {
  return (
    <SanityImage
      alt={image?.altText}
      crop={image?.crop}
      dataset={dataset}
      hotspot={image?.hotspot}
      layout="fill"
      objectFit={isFilled ? 'cover' : 'contain'}
      projectId={projectId}
      sizes="100vw"
      src={image?.asset?._ref}
    />
  );
}
