import { Box, Button, Card, Typography, darken } from '@mui/material';
import NextLink from 'next/link';
import { SanityAssetImage, SanityModuleOffer } from 'src/@types/sanity';
import { dataset, projectId } from 'src/lib/sanity.client';
import Routes from 'src/routes';
import { fPrice, fUnit } from 'src/utils/formatPrice';
import SanityImage from '../media/SanityImage';
import { useResponsive } from 'src/hooks';

type Props = {
  module: SanityModuleOffer;
};

export default function OfferModule({ module }: Props) {
  const { unit, image, slug, shortTitle, backgroundColor, variant, price } = module.offer;
  const isFilled = variant == 'fill';
  const isStandalone = variant == 'standalone';
  const isHidden = variant == 'hidden';
  const isMobile = useResponsive('down', 'md');

  return (
    <NextLink href={Routes.offer(slug.current)} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          px: isMobile ? 2 : 6,
          py: isFilled ? 0 : 3,
          bgcolor: isFilled ? 'paper.background' : backgroundColor,
          aspectRatio: isMobile ? '1/1' : '2/1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: isFilled && isMobile ? 'center' : 'flex-start',
          flexDirection: 'column',
          textAlign: isMobile ? 'center' : 'left',
          ...(isFilled && {
            border: '1px solid',
            borderColor: 'divider',
          }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: isMobile ? 'center' : 'flex-start',
            flexDirection: 'column',
            justifyContent: isFilled ? 'center' : isMobile ? 'flex-start' : 'center',
            textAlign: isMobile ? 'center' : 'left',
            width: isMobile ? 1 : 1 / 2,
            height: isStandalone || !isMobile ? 'min-content' : 1,
            zIndex: 1,
          }}
        >
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb={2}
            sx={{
              minHeight: '1lh',
              lineHeight: 1.2,
              fontWeight: isFilled ? 800 : 700,
              textShadow: isFilled ? '1px 1px 2px rgba(255,255,255,0.8)' : 'none',
              opacity: isHidden ? 0 : 1,
              color:
                backgroundColor && isStandalone ? darken(backgroundColor, 0.6) : 'text.primary',
            }}
          >
            {shortTitle}
          </Typography>
          <Button
            variant="contained"
            color="inherit"
            size={isMobile ? 'medium' : 'large'}
            sx={{ boxShadow: isHidden ? '1px 1px 10px rgba(255,255,255,0.8)' : 'none' }}
          >
            Fra kr {fPrice(price)} {unit && fUnit(unit)}
          </Button>
        </Box>
        {(isFilled || isHidden) && (
          <Box
            sx={{
              position: 'absolute',
              width: 1,
              height: 1,
              background: 'black',
              opacity: isHidden ? 1 : 0.5,
              left: 0,
            }}
          >
            <ModuleImage isFilled={true} image={image} />
          </Box>
        )}
        {isStandalone && (
          <Box
            sx={{
              position: isMobile ? 'relative' : 'absolute',
              width: isMobile ? 1 : 1 / 2,
              aspectRatio: 1 / 1,
              mb: isMobile ? -10 : -12,
              mt: -2,
              right: isMobile ? 0 : -30,
            }}
          >
            <ModuleImage isFilled={false} image={image} />
          </Box>
        )}
      </Card>
    </NextLink>
  );
}

function ModuleImage({ image, isFilled }: { image: SanityAssetImage; isFilled: boolean }) {
  return (
    <SanityImage
      alt={image?.altText}
      crop={image?.crop}
      dataset={dataset}
      hotspot={image?.hotspot}
      layout="fill"
      objectFit={isFilled ? 'cover' : 'contain'}
      objectPosition={'top'}
      projectId={projectId}
      sizes="100vw"
      src={image?.asset?._ref}
    />
  );
}
