// next
//
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material';
import { m } from 'framer-motion';
import NextLink from 'next/link';
import { ServiceProps } from 'src/@types/service';
import { MotionViewport, varFade } from 'src/components';
// routes
import Routes from '../../routes';
import ServiceItem from '../services/ServiceItem';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  services: ServiceProps[];
};

export default function LandingFeaturedServices({ services }: Props) {
  return (
    <RootStyle>
      <Container>
        <Stack
          spacing={3}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          width={1}
          component={MotionViewport}
          margin="0 auto"
        >
          <div>
            <Typography variant="overline" color="text.secondary">
              Elektrikertjenester
            </Typography>
            <Typography variant="h2" variants={varFade().in} component={m.h2}>
              Populære tjenester
            </Typography>
          </div>
          <Typography maxWidth={400} variant="body1">
            Vi har jobbet for lønnsomme og fremtidsrettede løsninger innen elektro i over 20 år. Det
            er ikke tilfeldig at vi i dag er blant de fremste elektrikerne i Bergen.
          </Typography>
        </Stack>

        <Box
          sx={{
            my: { xs: 3, md: 6 },
            mt: { xs: 6, md: 10 },
            display: 'grid',
            rowGap: { xs: 4, md: 5 },
            columnGap: { xs: 2, md: 2 },
            gridTemplateColumns: {
              xs: 'repeat(2, minmax(0, 1fr))',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            },
          }}
        >
          {services.map((service: ServiceProps) => (
            <ServiceItem key={service._id} service={service} />
          ))}
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <NextLink href={Routes.services} passHref legacyBehavior>
            <Button size="large" variant="contained">
              Se alle tjenester
            </Button>
          </NextLink>
        </Box>
      </Container>
    </RootStyle>
  );
}
