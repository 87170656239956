import playIcon from '@iconify/icons-carbon/play';
import {
  Box,
  BoxProps,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import staticLoader from '@utils/staticLoader';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useState } from 'react';
import { SanityAssetImage, SanityHeroHome } from 'src/@types/sanity';
import SanityImage from 'src/components/media/SanityImage';
import { dataset, projectId } from 'src/lib/sanity.client';
import Routes from 'src/routes';
import { Iconify, PlayerWithButton } from '../../components';

export default function LandingHero({ data }: { data: SanityHeroHome }) {
  const [openVideo, setOpenVideo] = useState(false);

  const theme = useTheme();

  const handleOpenVideo = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          overflow: 'hidden',
          borderBottom: '1px solid',
          borderColor: 'divider',
          background:
            'radial-gradient(46.23% 64.13% at 75.03% 48.21%, rgba(45, 116, 255, 0.11) 0%, rgba(51, 126, 255, 0.06) 47.06%, rgba(57, 136, 255, 0.00) 100%), #FFF',
          backgroundPositionY: -200,
        }}
      >
        <Container
          sx={{
            zIndex: 9,
            py: { xs: 10, md: 0 },
            pb: { xs: 0, md: 0 },
            pt: { xs: 3, md: 10 },
            mt: { xs: 12, md: 20 },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8}>
              <Typography variant="overline" sx={{ mb: 1, color: 'text.secondary', opacity: 0.8 }}>
                {data.overtitle}
              </Typography>
              <Typography variant="h1" sx={{ mb: 4, fontWeight: 800, lineHeight: '112.2%' }}>
                {data.title}
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, fontSize: theme.typography.h6.fontSize }}>
                {data.description}
              </Typography>

              <Stack
                spacing={{ xs: 2, sm: 2 }}
                alignItems={{ xs: 'stretch', sm: 'center' }}
                justifyContent={{ xs: 'center', sm: 'center' }}
                direction={{ xs: 'column', sm: 'row' }}
              >
                <NextLink href={Routes.form} passHref legacyBehavior>
                  <Button size="large" variant="contained">
                    Gratis befaring
                  </Button>
                </NextLink>
                <Button
                  endIcon={<Iconify icon={playIcon} />}
                  size="large"
                  color="inherit"
                  variant="outlined"
                  onClick={handleOpenVideo}
                >
                  Se video
                </Button>
              </Stack>

              <Box sx={{ mt: 4, mb: 10 }} />
            </Grid>
          </Grid>
          {data.content && (
            <Box
              width={1}
              sx={{
                aspectRatio: { xs: '3/2', sm: '4/2' },
                position: 'relative',
                borderRadius: (theme) =>
                  `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                overflow: 'hidden',
                mb: -1,
              }}
            >
              <ModuleContent content={data.content} />
            </Box>
          )}
        </Container>
      </Box>

      {/* <Container maxWidth="sm"> */}
      {/* <Card
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            py: 2,
            px: 3,
            mt: -4,
            mb: 5,
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <Typography variant="h4" maxWidth={isMobile ? '100%' : 300} sx={{ mt: isMobile ? 2 : 0 }}>
      {data.infoCardText} 
          </Typography>
          <Badges />
        </Card> */}
      <Container maxWidth="md">
        <Card
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            ml: 'auto',
            py: 3,
            mt: -4,
            mb: 5,
            zIndex: 1000,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            width: 1,
          }}
        >
          <Badges />
        </Card>
      </Container>
      {/* </Container> */}
      {/* <Divider /> */}

      <PlayerWithButton
        open={openVideo}
        onClose={handleCloseVideo}
        videoPath={'https://vimeo.com/287428521'}
      />
    </>
  );
}

const Badges = ({ sx }: BoxProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        position: 'relative',
        width: 1,
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Badge
        href="https://www.miljofyrtarn.no/"
        src="/static/brands/miljofyrtarn.png"
        alt="Miljøfyrtårn"
      />
      <Badge
        href="https://sgregister.dibk.no/enterprises/982743834"
        src="/static/brands/sentralt_godkjent.jpg"
        alt="Sentralt Godkjent"
      />
      <Badge
        href="https://utdanning.no/finnlarebedrift/bedrift/982743834/"
        src="/static/brands/laerebedrift.jpg"
        alt="Godkjent Lærebedrift"
      />
      <Badge href="" src="/static/brands/norgeseliten.png" alt="Medlem av Norgeseliten" />
      {/* <Badge
        href="https://sgregister.dibk.no/enterprises/982743834"
        src="/static/brands/ekomnett.png"
        alt="Ekomnett Autorisert"
      /> */}
      <Badge href="https://www.nelfo.no/" src="/static/brands/nelfo.png" alt="Medlem av Nelfo" />
    </Grid>
  );
};

const Badge = ({
  href,
  alt,
  src,
  height,
}: {
  href: string;
  src: string;
  alt: string;
  height?: any;
}) => {
  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={2}
      component="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        filter: 'grayscale(1)',
        opacity: 0.8,
        textDecoration: 'none',
        height: 80,
        '&:hover': {
          filter: 'grayscale(0)',
          opacity: 1,
        },
        ...(height && { maxHeight: height }),
      }}
    >
      <Box sx={{ width: 100, height: 80, position: 'relative' }}>
        <NextImage
          layout="fill"
          loader={staticLoader}
          src={src}
          alt={alt}
          objectFit="contain"
          unoptimized
        />
      </Box>
    </Grid>
  );
};

function ModuleContent({ content }: { content: SanityAssetImage }) {
  switch (content?._type) {
    case 'image': {
      return (
        <SanityImage
          alt={content?.altText}
          crop={content?.crop}
          dataset={dataset}
          hotspot={content?.hotspot}
          layout="fill"
          objectFit="cover"
          objectPosition="top"
          projectId={projectId}
          sizes="100vw"
          priority
          src={content?.asset?._ref}
        />
      );
    }
    default:
      return null;
  }
}
