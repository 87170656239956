import { Box, Card, Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
import { ServiceProps } from 'src/@types/service';
import Image from 'src/components/Image';
import { useResponsive } from 'src/hooks';
import Routes from 'src/routes';
// components
import { TextMaxLine } from '../../components';
import { varHover, varTranHover } from '../../components/animate';

// ----------------------------------------------------------------------

type Props = {
  service: ServiceProps;
};

export default function ServiceItem({ service }: Props) {
  const { title, header, slug } = service;
  const isMobile = useResponsive('down', 'md');

  return (
    <div>
      <Card
        component={m.div}
        whileHover="hover"
        sx={{
          '&:hover': {
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <NextLink href={Routes.service('[slug]')} as={Routes.service(slug.current)} passHref>
          <Box sx={{ overflow: 'hidden' }}>
            <m.div variants={varHover(1.1)} transition={varTranHover()}>
              <Image
                alt={slug.current}
                src={header}
                sizes={{ xs: 6, md: 3 }}
                style={{ objectFit: 'cover' }}
                height={200}
                width={400}
              />
            </m.div>
          </Box>
        </NextLink>
      </Card>
      <Stack spacing={0.5} sx={{ px: { xs: 0.5, sm: 1, md: 1 }, py: { xs: 2 } }}>
        {!isMobile && (
          <Typography variant="body3" sx={{ color: 'text.secondary' }}>
            Tjeneste
          </Typography>
        )}
        <NextLink
          href={Routes.service('[slug]')}
          as={Routes.service(slug.current)}
          passHref
          legacyBehavior
        >
          <TextMaxLine variant={isMobile ? 'h6' : 'h5'} asLink>
            {title}
          </TextMaxLine>
        </NextLink>
      </Stack>
    </div>
  );
}
