import { SanityModule } from 'src/@types/sanity';
import CallToActionModule from './CallToAction';
import OffersModule from './Offers';
import ServicesModule from './Services';
// import CalloutModule from '~/components/modules/Callout';
// import CollectionModule from '~/components/modules/Collection';
// import ImageModule from '~/components/modules/Image';
// import InstagramModule from '~/components/modules/Instagram';
// import ProductModule from '~/components/modules/Product';

type Props = {
  imageAspectClassName?: string;
  module: SanityModule;
};

export default function Module({ imageAspectClassName, module }: Props) {
  switch (module._type) {
    case 'module.callToAction':
      return <CallToActionModule module={module} />;
    case 'module.offers':
      return <OffersModule module={module} />;
    case 'module.services':
      return <ServicesModule module={module} />;
    // case 'module.collection':
    //   return <CollectionModule module={module} />;
    // case 'module.image':
    //   return <ImageModule module={module} />;
    // case 'module.instagram':
    //   return <InstagramModule module={module} />;
    // case 'module.product':
    //   return <ProductModule imageAspectClassName={imageAspectClassName} module={module} />;
    default:
      return null;
  }
}
